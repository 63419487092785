<template>
  <div class="product-container size-wrapper">
    <h3>{{ currentType.name }}: {{ data.name }}</h3>
    <h4>Описания опций</h4>
    <b-row v-for="(item, index) in descriptions" :key="item.id">
      <b-col v-if="currentType.id !== 'size'" md="5">
        <b-form-group
            label="Размер"
        >
          <v-select v-model="item.size" :options="allSizes" :disabled="!item.editable" label="name"
                    class="size-select"/>

        </b-form-group>
      </b-col>
      <b-col :md="currentType.id !== 'size' ? 5 : 10">
        <b-form-group
            label="Описание"
        >
          <b-form-textarea
              id="textarea"
              v-model="item.description"
              placeholder="Описание"
              :disabled="!item.editable"
          />
        </b-form-group>
      </b-col>
      <b-col class="icon">
        <feather-icon
            icon="EditIcon"
            style="width:20px;height:20px;margin-right:10px"
            @click="() => editSizeDescription(index)"
        />
        <feather-icon
            icon="TrashIcon"
            style="width:20px;height:20px;"
            @click="() => removeSizeDescription(item, index)"
        />
      </b-col>
      <b-row v-if="item.editable">
        <b-col>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1 save-button"
              @click="() => saveSizeDescription(item, index)"
          >
            {{ 'Coхранить' }}
          </b-button>
        </b-col>
      </b-row>
    </b-row>
    <p v-if="descriptions.length === 0">Нет описаний размеров</p>
    <div v-if="currentType.id === 'size' && descriptions.length !== 0"></div>
    <b-row v-else>
      <b-col>
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click="addDescription"
        >
          {{ 'Добавить описание' }}
        </b-button>
      </b-col>
    </b-row>
    <b-modal
        v-if="modal"
        id="modal-primary"
        v-model="modal"
        ok-only
        ok-title="Отмена"
        modal-class="modal-primary"
        centered
        title="Новое описание"
    >
      <b-row>
        <b-col v-if="currentType.id !== 'size'" md="6">
          <b-form-group
              label="Размер"
          >
            <v-select v-model="newDescription.size" :options="allSizes" label="name" class="size-select"/>
          </b-form-group>
        </b-col>
        <b-col :md="currentType.id !== 'size' ? 6 : 12">
          <b-form-group
              label="Описание"
          >
            <b-form-textarea
                id="textarea"
                v-model="newDescription.description"
                placeholder="Описание"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="mt-1"
          variant="primary"
          @click="addNewSizeDescription"
      >
        Добавить
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BModal,
  BFormTextarea
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import "vue-select/dist/vue-select.css";

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import crud_module from '../../../libs/crud_module'

const size_description_module = crud_module({
  entity_name: 'size-description',
})

export default {
  name: "SizeDescriptionInfo",
  props: {
    dataType: {
      type: Number
    },
    data: {
      type: Object,
      default: () => {
      }
    },
  },
  components: {
    BButton,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BModal,
    'v-select': vSelect,
    ToastificationContent,
    BFormTextarea
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      currentType: {},
      descriptions: [],
      modal: false,
      newDescription: {},
      currentTypeId: {},
      allSizes: []
    }
  },
  watch: {
    async data() {
      // TODO needs refactor
    },
    async dataType() {
      switch (this.dataType) {
        case 0:
          this.currentType = {
            name: 'Товар',
            id: 'product'
          }
          break;
        case 1:
          this.currentType = {
            name: 'Категория',
            id: 'category'
          }
          break;
        case 2:
          this.currentType = {
            name: 'Размер',
            id: 'size'
          }
          break;
        default:
          this.currentType = {
            name: 'Товар',
            id: 'product'
          }
          break;
      }
      this.currentTypeId = {}
      this.newDescription = {}
      switch (this.currentType.id) {
        case 'product':
          this.currentTypeId.product_uuid = this.data.id
          break;
        case 'category':
          this.currentTypeId.category_id = this.data.id
          break;
        case 'size':
          this.currentTypeId.product_option_value_uuid = this.data.uuid
          break;
        default:
          this.currentTypeId.product_uuid = this.data.id
          break;
      }
      await this.getDescriptions(this.currentTypeId)
    }
  },
  created() {
    this.currentType = {
      name: 'Товар'
    }
    const payload = {
      skipCount: 0,
      maxResultCount: 100,
    }
    this.$store.dispatch('shop/fetchOptions', payload)
        .then(() => {
          this.$store.state.shop.options.forEach(option => {
            // TODO remove hardcode
            if (option.uuid === "d527e0d3-f2f4-44c8-86f5-81c46c165fe1" && this.allSizes.length === 0) {
              this.allSizes = option.values
            }
          })
        })
  },
  methods: {
    async getDescriptions(payload) {
      const descriptions = await size_description_module.fetchItems(payload)
          .then(res => res.data)
      this.descriptions = descriptions.map(description => ({
        ...description,
        size: this.findCurrentSize(description.product_option_value_uuid),
        editable: false
      }))
    },
    addDescription() {
      this.modal = true
    },
    findCurrentSize(sizeId) {
      return this.allSizes.find(size => size.uuid === sizeId)
    },
    editSizeDescription(index) {
      this.descriptions[index].editable = !this.descriptions[index].editable
    },
    async saveSizeDescription(data, index) {
      let payload = {}
      if (this.currentType.id !== 'size') {
        payload = {
          ...this.currentTypeId,
          description: data.description,
          product_option_value_uuid: data.size.uuid
        }
      } else {
        payload = {
          ...this.currentTypeId,
          description: data.description,
        }
      }
      await size_description_module.updateItemPatch(data.uuid, payload)
          .then(response => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Уведомление',
                icon: 'BellIcon',
                text: 'Описание изменено',
                variant: 'success',
              },
            })
            this.descriptions[index].editable = false
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Уведомление',
                icon: 'BellIcon',
                text: error.response.data.message,
                variant: 'danger',
              },
            })
          })
    },
    async addNewSizeDescription() {
      let payload = {}
      if (this.currentType.id !== 'size') {
        payload = {
          ...this.currentTypeId,
          description: this.newDescription.description,
          product_option_value_uuid: this.newDescription.size.uuid
        }
      } else {
        payload = {
          ...this.currentTypeId,
          description: this.newDescription.description,
        }
      }
      await size_description_module.createItem(payload)
          .then(response => {
            this.modal = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Уведомление',
                icon: 'BellIcon',
                text: 'Описание добавлено',
                variant: 'success',
              },
            })
            this.getDescriptions(this.currentTypeId)
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Уведомление',
                icon: 'BellIcon',
                text: error.response.data.message,
                variant: 'danger',
              },
            })
          })
      this.newDescription = {}
    },
    async removeSizeDescription(descriptionId, index) {
      await size_description_module.deleteItem(descriptionId.uuid)
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Уведомление',
                icon: 'BellIcon',
                text: 'Описание удалено',
                variant: 'success',
              },
            })
            this.descriptions.splice(index, 1)
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Уведомление',
                icon: 'BellIcon',
                text: error.response.data.message,
                variant: 'danger',
              },
            })
          })
    }
  }
//   methods: {
//     parseCategories(data) {
//       data.forEach(category => {
//         this.allCategories.push(category)
//         if(category.children.length !== 0) {
//           this.parseCategories(category.children)
//         }
//       })
//     },
//     async addNewOptionData() {
//       if(this.modal.type === 'type') {
//         await product_option_module.createItem({ name: this.modal.newOptionData })
//         .then(response => {
//           this.modal.isOpen = false
//           this.$toast({
//             component: ToastificationContent,
//             props: {
//               title: 'Уведомление',
//               icon: 'BellIcon',
//               text: 'Опция добавлена',
//               variant: 'success',
//             },
//           })
//           this.getAllOptions()
//           return response
//         })
//         .catch(error => {
//           this.$toast({
//             component: ToastificationContent,
//             props: {
//               title: 'Уведомление',
//               icon: 'BellIcon',
//               text: error.response.data.message,
//               variant: 'danger',
//             },
//           })
//         })
//       } else {
//         const payload = {
//           name: this.modal.newOptionData,
//           product_option_uuid: this.product.options[this.modal.optionIndex].option.uuid
//         }
//         await product_option_value_module.createItem(payload)
//         .then(async response => {
//           this.modal.isOpen = false
//           this.$toast({
//             component: ToastificationContent,
//             props: {
//               title: 'Уведомление',
//               icon: 'BellIcon',
//               text: 'Значение опции добавлено',
//               variant: 'success',
//             },
//           })
//           const optionValues = await this.getOptionValues(payload.product_option_uuid)
//           this.product.options[this.modal.optionIndex].value = {}
//           this.product.options[this.modal.optionIndex].allValues = [{name: 'Добавить новое значение', create: true}, ...optionValues]
//           return response
//         })
//         .catch(error => {
//           this.$toast({
//             component: ToastificationContent,
//             props: {
//               title: 'Уведомление',
//               icon: 'BellIcon',
//               text: error.response.data.message,
//               variant: 'danger',
//             },
//           })
//         })
//       }
//     },
//     async changeOptionType(index, selectedOption) {
//       if(selectedOption.create) {
//         this.modal.isOpen = true
//         this.modal.text = 'Введите название новой опции'
//         this.modal.type = 'type'
//       } else {
//         const optionValues = await this.getOptionValues(selectedOption.uuid)
//         this.product.options[index].value = {}
//         this.product.options[index].allValues = [{name: 'Добавить новое значение', create: true}, ...optionValues]
//       }
//     },
//     changeOptionValue(index, selectedValue) {
//       if(selectedValue.create) {
//         this.modal.isOpen = true
//         this.modal.text = 'Введите название нового значения'
//         this.modal.type = 'value'
//         this.modal.optionIndex = index
//       }
//     },
//     async getOptionValues(optionId) {
//       const optionValues = await product_option_module.fetchItem(optionId, { query: { admin: '1' }})
//       .then(res => res.data.values)
//       return optionValues
//     },
//     removeOption(index) {
//       this.product.options.splice(index, 1)
//     },
//     addProductOption() {
//       const newOption = {
//         id: this.product.options.length + 1,
//         option: {},
//         value: {}
//       }
//       this.product.options.push(newOption)
//     },
//     async getAllOptions() {
//       const payload = {
//         skipCount: 0,
//         maxResultCount: 10000,
//       }
//       await product_option_module.fetchItems(payload)
//       .then(async res => {
//         this.allOptionTypes = [{name: 'Добавить новую опцию', create: true}, ...res.data.rows]
//       })
//     },
//     saveItem() {
//       const dataForUpdate = {
//         product: {
//           id: this.product.id,
//           name: this.product.name,
//           code: this.product.code,
//           description: this.product.description,
//           brand: this.product.brand,
//           model: this.product.model,
//           price: this.data.price,
//           purchasePrice: this.data.purchasePrice,
//           quantity: this.product.quantity,
//           codeList: this.product.codeList.map(code => {return {code_type: code.code_type, code: code.code}})
//         },
//         category_id: this.product.category.id,
//         options: this.product.options.map(option => {return {option_id: option.option.uuid, value_id: option.value.uuid}})
//       }
//       this.$store.dispatch('shop/updateItem', dataForUpdate)
//       .then(() => {
//         this.$toast({
//           component: ToastificationContent,
//           props: {
//             title: 'Успешно',
//             icon: 'BellIcon',
//             text: 'Товар изменен',
//             variant: 'success',
//           },
//         })
//       })
//       .catch(err => {
//         console.error(err)
//         this.$toast({
//           component: ToastificationContent,
//           props: {
//             title: 'Ошибка',
//             icon: 'BellIcon',
//             text: 'Некорректные данные',
//             variant: 'warning',
//           },
//         })
//       })
//       this.$emit('closeModal', false)
//     }
//   }
}
</script>

<style lang="scss" scoped>
.product-container {
  min-width: 40vw;
  padding: 20px 15px 15px;
  overflow-y: auto;
}

.icon {
  align-self: center;
  cursor: pointer;
}

.save-button {
  margin: 0 14px 14px;
}

#textarea {
  height: 38px;
  padding: 5px 10px !important;
}
</style>

<style lang="scss">
.size-select {
  height: 38px !important;

  .vs__dropdown-toggle {
    height: 100%;
  }
}
</style>
