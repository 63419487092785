<template>
    <div class="fixed-overlay-modal" :class="{'modal_open': open}">
        <div class="modal-window">
            <div class="modal_container">
                <div class="close-icon" @click="$emit('closeModal', false)">
                    <img src="../../assets/images/icons/close-icon.svg" alt="">
                </div>
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: "ModalWindow",
  props: ['showModal'],
  data() {
    return {
        open: false
    }
  },
  watch: {
    showModal() {
        this.open = this.showModal
    }
  },
  created() {
  },
  computed: {   
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.fixed-overlay-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    overflow: auto;
    -webkit-transform: translate3d(0, 0, 0);
    background: rgba(37, 44, 65, .7);
    opacity: 0;
    visibility: hidden;
    transition: all ease .3s;

    .modal-window {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        .modal_container {
            position: relative;
            min-width: 200px;
            min-height: 100px;
            margin-bottom: 50px;
            background: #fff;
            border-radius: 6px;
            overflow: hidden;
            z-index: 99999999;
            box-shadow: 0px 0px 0px 7px rgba(255, 255, 255, 0.1);
        }
    }

    .close-icon {
        position: absolute;
        right: 7px;
        top: 5px;
        cursor: pointer;
        z-index: 3;
    }
}

.modal_open {
    opacity: 1;
    visibility: visible;
}

</style>